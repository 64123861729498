import { render, staticRenderFns } from "./ClinicContent.vue?vue&type=template&id=555777af&scoped=true"
import script from "./ClinicContent.vue?vue&type=script&lang=js"
export * from "./ClinicContent.vue?vue&type=script&lang=js"
import style0 from "./ClinicContent.vue?vue&type=style&index=0&id=555777af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "555777af",
  null
  
)

export default component.exports