
    import SamedayBackgroundPhoto from '../../UserInterface/photo/SamedayBackgroundPhoto'

    export default {
        name: 'ClinicPhoto',
        components: { SamedayBackgroundPhoto },
        props: {
            photoUrl: { type: String, required: true },
            squareFormat: { type: Boolean, default: false },
        },
    }
