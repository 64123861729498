
    import { Button, Tag } from 'ant-design-vue'
    import SamedayButton from '../../UserInterface/button/SamedayButton'
    import { Tooltip } from 'ant-design-vue'

    export default {
        name: 'ClinicContent',
        components: { Button, Tag, SamedayButton, Tooltip },
        props: {
            clinic: { type: Object, required: true },
        },
        computed: {
            address () {
                return this.clinic.address
            },

            secondAddressLine () {
                if (this.clinic.type === 'physical') {
                    return [
                        this.address.city,
                        this.address.state,
                        this.address.zipCode
                    ].join(', ')
                }
                else if (this.address.city) {
                    return this.address.city
                } else {
                    return ''
                }
            },

            displayLocationIcon () {
                return this.clinic.type === 'physical'
            },

            googleMapsUrl () {
                const address = this.address.address + ', ' + this.secondAddressLine
                return `https://www.google.com/maps/search/?api=1&query=${address} Sameday Health`
            },

            /* reduce fontsize for clinic title if too long */
            clinicNameClass () {
                return this.clinic.name.length > 25 ? 'clinic-name-small' : ''
            }
        },
        methods: {
            // TEMPORARY solution until changes via retool.
            tagName (name) {
                if (name === 'Indoor') {
                    return 'Clinic'
                } else if (name === 'Walk-up') {
                    return 'Pop up'
                } else if (name === 'Wellness Treatments') {
                    return 'Wellness'
                } else {
                    return name
                }
            }
        },
    }
