
    import $ from 'bucks-js'
    import ClinicPhoto from './components/ClinicPhoto'
    import ClinicContent from './components/ClinicContent'

    export default {
        name: 'Clinic',
        components: { ClinicPhoto, ClinicContent },
        props: {
            clinic: { type: Object, required: true },
        },
        computed: {
            url () {
                return '/booking/' + this.clinic.stringId;
            },
        },
        methods: {
            /**
             * Opens booking page on click.
             */
            openBooking (event) {
                if ($.ui.isClient()) {
                    const query = $.urlQueryParam({
                        service: this.$route.params.service || this.$route.query.service,
                        ...this.$route.query,
                    })

                    if (this.$route.query.inline) {
                        this.$store.commit('booking/setValue', { key: 'hideNavigationBar', value: true })
                        this.$store.commit('booking/setValue', { key: 'disableCanvasColumn', value: true })
                        this.$router.push({ path: this.url + query })
                    } else {
                        window.open(this.url + query, '_blank')
                    }
                }
            },
        },
    }
