
    import { Button, Tag } from 'ant-design-vue'

    export default {
        name: 'VirtualClinicContent',
        components: { Button, Tag },
        props: {
            clinic: { type: Object, required: true },
        },
        computed: {
            serviceTags () {
                return this.clinic.tags.filter(tag => tag.type === 'clinic_services')
            },
            displayName () {
                return this.clinic.name.split(' House Calls')[0]
            }
        },
    }
